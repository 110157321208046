import { filter, lastValueFrom } from 'rxjs';
import { Component, TemplateRef, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, inject, viewChild } from '@angular/core';
import { ShipmentsClient, ReviewReasonEnum, FiltersClient, ListTypeEnum, GridColumnDto, ShipmentListDto, FilterItemDto, FilterTypeEnum, ColumnTypeEnum, ServiceTypeEnum, LoadTypeEnum, SelectOptionDto, ReferenceTypeEnum, ShipmentDto, CustomerAccessLevelEnum, PagedRequestDto } from '../../core/services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { UserResolverService } from '../../core/services/user-resolver.service';
import { SpinnerService } from '../../core/spinner/spinner.svc';
import { RequestService } from '../../core/services/request.service';
import { GridBaseComponent } from '../../shared/base-components/material-grid-base/material-grid-api-base.component';
import { SnackBarService } from '../../core/services/snack-bar.service';
import { UserSettingService } from '../../core/services/user-setting.service';
import { DocumentsDialogComponent } from '../../shared/documents-dialog/documents-dialog.component';
import { SendBulkSMSModalComponent } from '../../shared/send-bulk-sms-modal/send-bulk-sms-modal.component';
import { FilterByLoadNumberModalComponent, LoadNumberFilter } from '../../shared/filter-by-load-number-modal/filter-by-load-number-modal.component';
import { TrackingDetailsModalComponent } from '../../shared/tracking-details-modal/tracking-details-modal.component';
import { LocationHelper } from '../../core/locations-helper';
import { GridFilterSlideOutComponent } from '../../shared/grid-filter-slide-out/grid-filter-slide-out.component';
import { PermissionCheckerService } from '../../core/services/permission-checker.service';
import { Permissions } from '../../shared/common/permissions';
import { DateTime } from 'luxon';
import { saveAs } from 'file-saver-es';
import { LoadOptionsPipe } from '../../shared/pipes/load-options.pipe';
import { HasPermissionDirective } from '../../shared/directives/haspermission.directive';
import { MaterialGridEmptyColumnsComponent } from '../../shared/material-grid-empty-columns/material-grid-empty-columns.component';
import { MaterialGridEmptyTableComponent } from '../../shared/material-grid-empty-table/material-grid-empty-table.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { GridColumnDisplayComponent } from '../../shared/grid-column-display/grid-column-display.component';
import { CarrierInfoTooltipComponent } from '../../shared/carrier-info-tooltip/carrier-info-tooltip.component';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DateFilterSelectorComponent, DateSelectorTypeEnum } from '../../shared/widgets/date-filter-selector/date-filter-selector.component';
import { CustomerSelectorComponent } from '../../shared/widgets/customer-selector/customer-selector.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgClass } from '@angular/common';
import { ServiceTypesService } from '../../core/services/service-types.service';

@Component({
    templateUrl: './shipments.component.html',
    styleUrls: ['./shipments.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatTooltipModule, CustomerSelectorComponent, DateFilterSelectorComponent, MatFormFieldModule, MatInputModule, FormsModule, MatIconModule, MatButtonModule, MatMenuModule, MatCheckboxModule, MatTableModule, MatSortModule, RouterLink, CarrierInfoTooltipComponent, GridColumnDisplayComponent, MatPaginatorModule, MaterialGridEmptyTableComponent, MaterialGridEmptyColumnsComponent, HasPermissionDirective, GridFilterSlideOutComponent, LoadOptionsPipe, NgClass]
})
export class ShipmentListComponent extends GridBaseComponent<ShipmentListDto> {
  protected _matDialog: MatDialog;
  protected _userResolverService: UserResolverService;
  protected _spinnerService: SpinnerService;
  protected _snackBarService: SnackBarService;
  protected _requestService: RequestService;
  protected _filtersClient: FiltersClient;
  protected _userSettingService: UserSettingService;
  private _shipmentsClient = inject(ShipmentsClient);
  private _cdr = inject(ChangeDetectorRef);
  private _route = inject(ActivatedRoute);
  private permissionCheckerService = inject(PermissionCheckerService);
  private _router = inject(Router);
  private _serviceTypesService = inject(ServiceTypesService);

  readonly filterDialog = viewChild<TemplateRef<any>>('filterDialog');
  readonly trackingDialog = viewChild<TemplateRef<any>>('trackingDialog');
  readonly filterButton = viewChild.required(GridFilterSlideOutComponent);

  loadOptionValues: SelectOptionDto[] = [];
  showFilters: boolean = false;
  isASMUser: boolean = false;
  loadTypes = LoadTypeEnum;
  reviewReasons = ReviewReasonEnum;
  paramMap: { [name: string]: string } = {};
  hasAddShipmentPermission: boolean = false;
  drillDownInit: boolean = false;
  noCustomer = false;
  noDate = false;
  showAll = false;
  permissions = Permissions;
  listTypesEnum = ListTypeEnum;
  accessLevelEnum = CustomerAccessLevelEnum;
  viewCustomerIds: number[] = [];

  // properties if drilled down from dispatcher dashboard
  dispatchDashboardDrilldown: boolean = false;
  dispatchStartDate: Date | null = null;
  dispatchEndDate: Date | null = null;
  dispatchMeta: string;

  constructor() {
    const _matDialog = inject(MatDialog);
    const _userResolverService = inject(UserResolverService);
    const _spinnerService = inject(SpinnerService);
    const _snackBarService = inject(SnackBarService);
    const _requestService = inject(RequestService);
    const _filtersClient = inject(FiltersClient);
    const _userSettingService = inject(UserSettingService);

    super(_matDialog, _userResolverService, _spinnerService, _snackBarService, _filtersClient, _userSettingService, _requestService);
    this._matDialog = _matDialog;
    this._userResolverService = _userResolverService;
    this._spinnerService = _spinnerService;
    this._snackBarService = _snackBarService;
    this._requestService = _requestService;
    this._filtersClient = _filtersClient;
    this._userSettingService = _userSettingService;

    this.listTypes = ListTypeEnum.Shipments;
    this.paramMap["delivery-day"] = "scheduledDeliveryDay";
    this.hasLoadNumbersFilter = true;
    this._userResolverService.userInfo.subscribe(x => {
      this.isASMUser = x.IsASM;
      this.viewCustomerIds = x.ViewCustomerIds;
    });

    this.checkAddShipmentPermission();
    this._userSettingService.globalDateFilter$.subscribe(x => {
      this.dateFilter = x;
    });
  }

  ngOnInit(): void {
    if (this._route.snapshot.paramMap.keys.length > 0 || this._route.snapshot.url.find(f => f.path.includes('drilldown-default'))) {
      this.drillDownInit = true;
    }
    if (this._route.snapshot.url.find(f => f.path.includes('dispatch-dashboard-drilldown'))) {
      this.dispatchDashboardDrilldown = true;
    }
    super.ngOnInit();
  }

  getData() {
    if(this.drillDownInit) {
      this.drillDownInit = false;
      if(this.savedState.selectedFilterIds.length > 0) {
        this.clearUserDefinedFiltersSilent()
      };
    }

    let request = this.getPagedRequest();
    this.setFiltersFromRouteParameters(request);

    if(this.selectedFilters.length > 0 && !this.filterTitle) this.searchTerm = "";

    var otherFilters = this.getFilterFromQueryParam();
    request.filterItems.push( ...otherFilters);
    return this._shipmentsClient.shipments_GetDynamicPagedList(request, this.searchTerm, this.showAll);
  }

  setFiltersFromRouteParameters(request: PagedRequestDto): void {
    const keys = this._route.snapshot.paramMap.keys;
    if(keys.length > 0) {
      keys.forEach(param => {
        if(param == "title" || param == "carrierPerformance")  {
          return;
        }

        let colName = this.paramMap[param] || param;
        let value = this._route.snapshot.paramMap.get(param);
        let col = this.allColumns.filter(x => x.columnName == colName)[0];
        let filterItem = FilterItemDto.fromJS({
          column: colName,
          value1: value,
          filterType: FilterTypeEnum.Equals
        });

        if(col) {
          filterItem.columnType = col.columnType;
        }

        // set default title
        let title = value && !(param == "dispatch-start-date" || param == "dispatch-end-date" || param == "customerId") ? value.split("-").join(" ") : '';
        this.filterTitle = `${title}`;

        switch (param) {
          case "performance":
            this.setPerformanceParams(filterItem, value ?? "");
            break;
          case "dispatch-meta":
              var defaultFilterColumnDispatch = this.allColumns.find(x => x.defaultDateFilter);
              this.dispatchMeta = value;
              var defaultDateFilter = request?.filterItems?.find(f => f.column == defaultFilterColumnDispatch?.columnName)
              if(defaultDateFilter) {
                defaultDateFilter.filterType = FilterTypeEnum.Equals
                defaultDateFilter.meta = value?.split("-").join(" ");
                defaultDateFilter.value1 = null;
                defaultDateFilter.value2 = null;
              }
              this.dateFilter.meta = defaultDateFilter.meta;
              this.dateFilter.startDate = null;
              this.dateFilter.selectorType = DateSelectorTypeEnum.Generic;
              this.dateFilter.endDate = null;
              filterItem = null;
              break;
          case "dispatch-start-date":
              var defaultFilterColumnDispatch = this.allColumns.find(x => x.defaultDateFilter);
              var defaultDateFilter = request?.filterItems?.find(f => f.column == defaultFilterColumnDispatch?.columnName)
              if(defaultDateFilter) {
                defaultDateFilter.filterType = FilterTypeEnum.Equals
                defaultDateFilter.meta = null
                defaultDateFilter.value1 = value;
                defaultDateFilter.value2 = null;
              }
              this.dateFilter.meta = null
              this.dateFilter.startDate = DateTime.fromISO(value);
              this.dateFilter.selectorType = DateSelectorTypeEnum.Specific;
              this.dateFilter.endDate = null;
              filterItem = null;
              break;
          case "dispatch-end-date":
              var defaultFilterColumnDispatch = this.allColumns.find(x => x.defaultDateFilter);
              var defaultDateFilter = request?.filterItems?.find(f => f.column == defaultFilterColumnDispatch?.columnName)
              if(defaultDateFilter) {
                defaultDateFilter.filterType = FilterTypeEnum.Between
                defaultDateFilter.value2 = value;
              }
              this.dateFilter.endDate = DateTime.fromISO(value);
              filterItem = null;
              break;
          case "delivery-day":
            let defaultFilterColumn = this.allColumns.find(x => x.defaultDateFilter);
            var defaultDateFilter = request?.filterItems?.find(f => f.column == defaultFilterColumn?.columnName)
            if(defaultDateFilter) {
              defaultDateFilter.column = 'scheduledDeliveryDate';
              defaultDateFilter.filterType = FilterTypeEnum.BetweenExact
              defaultDateFilter.meta = undefined;
              defaultDateFilter.value1 = DateTime.now().toUTC().toString();
              defaultDateFilter.value2 = DateTime.now().toUTC().plus({ days: 7 }).toString();
            }
            var inboundFilter = FilterItemDto.fromJS({
              column: "travelDirection",
              columnType: ColumnTypeEnum.Text,
              filterType: FilterTypeEnum.Equals,
              value1: "Inbound",
            });
            request?.filterItems?.push(inboundFilter);
            var hasActualPickupDateFilter = FilterItemDto.fromJS({
              column: "actualPickupDate",
              columnType: ColumnTypeEnum.Text,
              filterType: FilterTypeEnum.IsNotEmpty
            });
            request?.filterItems?.push(hasActualPickupDateFilter);
            this.noDate = true;
            this.noCustomer = false;
            break;

          case "expedited-supplier": // this should use Id in the future, but previous state was using name so we will keep it
            var expediatedFilter = FilterItemDto.fromJS({
              column: "loadOptions",
              columnType: ColumnTypeEnum.Flags,
              filterType: FilterTypeEnum.Contains,
              value1: "2048", // Expedited
            });
            request?.filterItems?.push(expediatedFilter);

            filterItem.columnType = ColumnTypeEnum.Text;
            filterItem.column = "DestinationCompany";
            filterItem.value1 = value?.split("-").join(" ");
            this.filterTitle = "Expedited," + this.filterTitle;
            this.noDate = false;
            this.noCustomer = true;
            break;

          case "carrierID":
            this.filterTitle = `${this._route.snapshot.paramMap.get("title")}`;
            filterItem.columnType = ColumnTypeEnum.Carrier;
            var carrierPerformance = this._route.snapshot.paramMap.get("carrierPerformance")
            if(carrierPerformance) {
              this.filterTitle += "," + carrierPerformance;
              let carrierPerformanceFilterItem = FilterItemDto.fromJS({
                column: "",
                value1: "",
                filterType: FilterTypeEnum.Equals
              });
              this.setPerformanceParams(carrierPerformanceFilterItem, carrierPerformance);
              request?.filterItems?.push(carrierPerformanceFilterItem);
            }
            break;

          case "serviceType":
            filterItem.value1 = Object.keys(ServiceTypeEnum).indexOf(value?.split("-").join("") ?? "").toString();
            filterItem.filterType = FilterTypeEnum.Contains;
            filterItem.column = "serviceType";
            break;
          case "assignedBy":
          case "createdBy":
            let column = param == "createdBy" ? "Created By" : "Assigned By";
            this.filterTitle = `${column}: ${value}`;
            filterItem.filterType = FilterTypeEnum.Equals;
            filterItem.columnType = ColumnTypeEnum.Text;
            filterItem.column = param;
            filterItem.value1 = value ?? "";
            break;
          case "customerId":
            if (this.dateFilter)  {
              this.dateFilter.customerId = Number(value);
            }
            break;
          default:
            break;
        }
        if(filterItem) {
          request?.filterItems?.push(filterItem);
        }
        if(this.dateFilter?.customerId && !this.noCustomer) {
          request?.filterItems?.push(this.getCustomerFilter());
        }
        if(this.dispatchDashboardDrilldown) {
          this.filterTitle = this.filterTitle ? this.filterTitle + ", ASM Only" : "ASM Only";
          request?.filterItems?.push(this.getAssignedByASMOnlyFilter());
        }
      });
    }
  }

  filterButtonClick() {
    this.filterButton().open(true);
  }

  bulkSMS() {
    this._requestService.get(this._shipmentsClient.shipments_GetLoadNumbersForBulkSMS(this.getPagedRequest(), this.searchTerm), x => {
      let dialogRef = this._matDialog.open(SendBulkSMSModalComponent, {
        data: x,
        width: "500px",
        disableClose: true
      });
      dialogRef.afterClosed().subscribe((x: any) => {
        if(x) {
          this._requestService.execute(this._shipmentsClient.shipments_SendBulkSMS(x.loadNumbers, x.message), x => {},
            "Bulk SMS sent.",
            "Bulk SMS failed to send.")
        }
      })
    }, "Bulk SMS LoadNumbers");
  }

  setColumns() {
    super.setColumns();
    this.loadOptionValues = this.allColumns.find(x => x.columnName == "loadOptions")?.selectOptions ?? [];
  }

  detectChanges() {
    this._cdr.detectChanges();
  }

  getExcelExportData() {
    const pageRequest = this.getPagedRequest();
    this.setFiltersFromRouteParameters(pageRequest);

    return this._shipmentsClient.shipments_ExportDynamicList(pageRequest, this.searchTerm, Intl.DateTimeFormat().resolvedOptions().timeZone, this.showAll);
  }

  getShipmentItemsExcelExportData() {
    const pageRequest = this.getPagedRequest();
    this.setFiltersFromRouteParameters(pageRequest);

    return this._shipmentsClient.shipments_ExportShipmentItemsDynamicList(pageRequest, this.searchTerm, Intl.DateTimeFormat().resolvedOptions().timeZone, this.showAll);
  }

  exportLineItemsToExcel() {
    this._requestService.get(this.getShipmentItemsExcelExportData(), x => {
      this._spinnerService.hide();
      var timeStamp = DateTime.now().toFormat("MMddyyyy");
      saveAs(x.data, `${this.listTypes}_Line-Item-Detail_${timeStamp}.xlsx`);
    })
  }

  getOriginState(shipment: ShipmentListDto) {
    return LocationHelper.getStateDisplayName(shipment.originState ?? "", shipment.originCountryISO2 ?? "");
  }

  getDestinationState(shipment: ShipmentListDto) {
    return LocationHelper.getStateDisplayName(shipment.destinationState ?? "", shipment.destinationCountryISO2 ?? "");
  }

  filterByLoadNumber() {
    let dialogRef = this._matDialog.open(FilterByLoadNumberModalComponent, {
      data: this.dateFilter?.loadNumbers,
      width: "500px"
    });
    dialogRef.afterClosed().subscribe((x: LoadNumberFilter) => {
      if(x) {
        if(this.dateFilter) {
          if(x.toRemove) {
            this.dateFilter.loadNumbers = "";
          }
          else {
            this.dateFilter.loadNumbers = x.loadNumberListString;
          }
          this.loadNumberChanged(this.dateFilter.loadNumbers);
        }
      }
    })
  }

  setPerformanceParams(filterItem: FilterItemDto, value: string) {
    filterItem.columnType = ColumnTypeEnum.Boolean;

    switch (value) {
      case "On-Time-Pickups":
        filterItem.column = "isLatePickup";
        filterItem.value1 = "false";
        break;

      case "On-Time-Deliveries":
        filterItem.column = "isLateDelivery";
        filterItem.value1 = "false";
        break;

        case "Late-Pickups":
        filterItem.column = "isLatePickup";
        filterItem.value1 = "true";
        break;

      case "Late-Deliveries":
        filterItem.column = "isLateDelivery";
        filterItem.value1 = "true";
        break;

      default:
        break;
    }
  }

  cellHasFlag(column: GridColumnDto, shipment: ShipmentListDto): boolean {
    if((column?.columnName?.indexOf("DeliveryDate") ?? -1) > -1 && this.hasFlag(shipment, ReviewReasonEnum.LateDelivery)) return true;
    if((column?.columnName?.indexOf("PickupDate") ?? -1) > -1 && this.hasFlag(shipment, ReviewReasonEnum.LatePickup)) return true;
    return false;
  }

  hasFlag(shipment: ShipmentListDto, enumValue: ReviewReasonEnum): boolean {
    return shipment?.reviewReason ? enumValue === (shipment?.reviewReason & enumValue) : false;
  }

  openTrackingDetails(loadNumber: number) {
    this._matDialog.open(TrackingDetailsModalComponent, {
      width: '65%',
      data: {loadNumber: loadNumber}
    });
  }

  openDocumentDetails(loadNumber: number, serviceType: ServiceTypeEnum, shipment: ShipmentDto) {
    this._matDialog.open(DocumentsDialogComponent, {
      data: {
        loadNumber: loadNumber,
        serviceType: serviceType,
        referenceType: ReferenceTypeEnum.Shipment,
        shipment: shipment
      }
    });
  }

  getGridColumns() {
    var columns = this._shipmentsClient.shipments_GetGridOptions();
    return columns;
  }

  trackLoad(index: number, item: ShipmentListDto) {
    return item.loadNumber;
  }

  deleteDrillDown() {
    this.filterTitle = '';
    this._router.navigate(['shipments'])
  }

  deleteLoadFilter() {
    this.loadNumberChanged('');
  }

  newShipment() {
    this._router.navigate(['/shipments/new']);
  }

  async checkAddShipmentPermission() {
    this.hasAddShipmentPermission = await this.permissionCheckerService.isGranted(Permissions.SHIPMENTS_CREATE);
  }

  getFilterFromQueryParam() {
    var filters: FilterItemDto[] = [];
    const keys = this._route.snapshot.queryParamMap.keys;

    for (const element of keys) {
      switch (element) {
        case 'serviceTypes':
          const selectedServiceTypesIndex = this._route.snapshot.queryParamMap.get(element);
          if (!this._serviceTypesService.isAllServiceTypeSelected(selectedServiceTypesIndex)) {
            let filterItem = FilterItemDto.fromJS({
              column: "serviceType",
              value1: selectedServiceTypesIndex,
              filterType: FilterTypeEnum.Contains,
              columnType: ColumnTypeEnum.SelectNumeric
            });
            filters.push(filterItem)

            const serviceTypeIds: number[] = selectedServiceTypesIndex.split(",").map(Number);

            for (const serviceTypeId of serviceTypeIds) {
              this.filterTitle = this.filterTitle?.length ? `${this.filterTitle}, ${this._serviceTypesService.getEnumValueByIndex(serviceTypeId)}`
              : `${this._serviceTypesService.getEnumValueByIndex(serviceTypeId)}`;
            }

          }
          break;
        case 'assignedBy':
          let filterItem = FilterItemDto.fromJS({
            column: "assignedBy",
            value1: this._route.snapshot.queryParamMap.get(element),
            filterType: FilterTypeEnum.Equals,
            columnType: ColumnTypeEnum.Text
          });
          filters.push(filterItem);
          this.filterTitle = this.filterTitle ? this.filterTitle + "," + filterItem.value1 : filterItem.value1;
          break;
        default:
          break;
      }
    };

    return filters;
  }
}
